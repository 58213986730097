import React from 'react';
import {useAsync} from 'react-use';

import * as UserFacade from '@app/facade/user';
import useUser from '@app/hooks/user/useUser';
import {useTypedRouter} from '@app/hooks/useTypedRouter';
import {RedirectionState, useQueryState} from '@app/misc/query-state';
import OnboardingCompletedProfileGuard from './OnboardingCompletedProfileGuard';

type LoggedInUserOnlyGuardProps = {
  shouldRedirectToOriginalPath?: boolean;
  children?: React.ReactNode;
};

// FIXME: https://riiid-pioneer.atlassian.net/browse/TN-2600
const NonAnonymousOnly = ({
  shouldRedirectToOriginalPath = false,
  children,
}: LoggedInUserOnlyGuardProps): React.ReactElement | null => {
  const isRedirectedRef = React.useRef(false);

  const router = useTypedRouter();
  const queryState = useQueryState();
  const {data: user, isFetching, isSuccess} = useUser({suspense: true});

  const isAnonymousUser = isSuccess && UserFacade.getIsUserAnonymous(user);

  const shouldGoToOnboardingIntroPage = isAnonymousUser;

  useAsync(async () => {
    if (isFetching || isRedirectedRef.current) {
      return;
    }

    if (shouldGoToOnboardingIntroPage) {
      const {pathname, query} = router;
      if (shouldRedirectToOriginalPath) {
        const originalFullPath = `${pathname}?${new URLSearchParams(query as Record<string, string>).toString()}`;
        await router.replace({
          pathname: '/onboarding/intro',
          query: {...new RedirectionState({redirectTo: originalFullPath}).toQuery(), loginAlert: true},
        });
      } else {
        await router.replace({pathname: '/onboarding/intro', query: queryState.toQuery()});
      }
    }

    isRedirectedRef.current = true;
  }, [router, isFetching, shouldGoToOnboardingIntroPage]);

  return shouldGoToOnboardingIntroPage ? null : (
    // TODO: 추후 분리하기
    <OnboardingCompletedProfileGuard>{children}</OnboardingCompletedProfileGuard>
  );
};

const LoggedInUserOnlyGuard = (props: LoggedInUserOnlyGuardProps) => {
  return <NonAnonymousOnly {...props} />;
};

export default LoggedInUserOnlyGuard;
